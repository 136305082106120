import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TileView from "./TilesetView";
import Grid from "@material-ui/core/Grid";
import { Route, Switch } from "react-router-dom";
import DesignsView from "./DesignsView";
import NavBar from "./NavBar";

const styles = theme => ({
  typography: {
    useNextVariants: true
  },
  root: {
    flexGrow: 1,
    minHeight: "100vh",
    backgroundColor: theme.palette.background.paper
  },
  toolbar: theme.mixins.toolbar
});

class MainView extends Component {
  state = {
    openSettings: false,
    openSelections: false
  };

  handleToggleSelections = event => {
    this.setState({ openSelections: !this.state.openSelections });
  };

  handleToggleSettings = event => {
    this.setState({ openSettings: !this.state.openSettings });
  };
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Typography component="div" style={{ padding: 8 * 3 }}>
          <Switch>
            <Route
              path="/"
              exact
              render={() => (
                <React.Fragment>
                  <NavBar
                    onToggleSelections={this.handleToggleSelections}
                    onToggleSettings={this.handleToggleSettings}
                    hideSettingsIcon={true}
                    hideSelectionIcon={true}
                  />

                  <Grid container spacing={24}>
                    <DesignsView />
                  </Grid>
                </React.Fragment>
              )}
            />
            <Route
              path="/design/:designId(\d+)(/colorways)?"
              render={props => (
                <React.Fragment>
                  <NavBar
                    onToggleSelections={this.handleToggleSelections}
                    onToggleSettings={this.handleToggleSettings}
                  />
                  <Grid container spacing={24}>
                    <TileView
                      {...props}
                      openSettings={this.state.openSettings}
                      onToggleSettings={this.handleToggleSettings}
                      openSelections={this.state.openSelections}
                      onToggleSelections={this.handleToggleSelections}
                    />
                  </Grid>
                </React.Fragment>
              )}
            />
            }
          </Switch>
        </Typography>
      </div>
    );
  }
}

MainView.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MainView);
