import Axios from "./baseUrl";

/**
 * Get
 */

export function getBrands() {
  return Axios.get("brands");
}

export function getDesigns(brandId, page = 1, pagesize = 80) {
  return Axios.get(
    `brands/${brandId}/designs?pageNumber=${page}&pageSize=${pagesize}`
  );
}

export function getColorways(designId) {
  return Axios.get(`designs/${designId}/colorways`);
}

export function getContentPackages(brandId, designId) {
  return Axios.get(`contentpackages?brandId=${brandId}&designId=${designId}`);
}

export function getTileRepeat(colorwayId, tilesetId, maxPixelWidth) {
  return Axios.get(
    `colorways/${colorwayId}/contentEntryTilesets/${tilesetId}/repeatimage?maxPixelWidth=${maxPixelWidth}`
  );
}

export function getTilesets(contentPackageId) {
  return Axios.get(`contentpackages/${contentPackageId}/tilesets`);
}

export function getTileOffsets(tilesetId) {
  return Axios.get(`contentEntryTilesets/${tilesetId}`);
}

export function getTileImage(colorwayId, tilePixelWidth) {
  return Axios.get(`colorways/${colorwayId}/binary`);
}

export function getBrand(designId) {
  return Axios.get(`designs/${designId}/brand`);
}

/**
 * Add/Update
 */
export function updateDesignContentPackage(
  designId,
  contentPackageId,
  islocked = false
) {
  return Axios.post("designcontentpackage", {
    DesignId: designId,
    DesignContentPackageId: -1,
    ContentPackageId: contentPackageId,
    Locked: islocked
  });
}
