import React from "react";
import Drawer from "@material-ui/core/Drawer";
import TileSelectorsMenu from "../../containers/TileSelectorsMenu";
import { withStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";

const drawerWidth = 240;

const styles = theme => ({
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    justifyContent: "flex-end"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  toolbar: theme.mixins.toolbar
});

function SelectionsDrawer(props) {
  function toggleSelections(event) {
    props.onToggleSelections(event);
  }
  function onSelectionChange(event) {
    props.handleSelectionChange(event);
  }
  function onBackButtonClick(event) {
    props.handleBackButtonClick(event);
  }

  const { classes } = props;
  const colorwayOption = props.colorwayOption;
  const contentPackageOption = props.contentPackageOption;
  const tilesetOption = props.tilesetOption;

  const colorwayItems = props.colorwayItems;
  const contentPackages = props.contentPackages;
  const tilesets = props.tilesets;

  return (
    <Drawer
      anchor="left"
      variant="persistent"
      open={props.openSelections}
      onClose={toggleSelections}
      className={classes.drawer}
    >
      <div className={classes.toolbar} />
      <Button color="primary" aria-label="Back" onClick={onBackButtonClick}>
        <ArrowBackIcon className={classes.icon} />
        Back to Design Selection
      </Button>
      <TileSelectorsMenu
        handleSelectionChange={onSelectionChange}
        colorwayOption={colorwayOption}
        contentPackageOption={contentPackageOption}
        tilesetOption={tilesetOption}
        colorwayItems={colorwayItems}
        contentPackages={contentPackages}
        tilesets={tilesets}
      />
    </Drawer>
  );
}

export default withStyles(styles)(SelectionsDrawer);
