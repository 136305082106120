import axios from "axios";

const tilecutApi = axios.create({
  baseURL: `${window.location.origin}/api`
});

tilecutApi.interceptors.request.use(
  request => {
    console.log(request);
    return request;
  },
  error => {
    console.log(error);
    return Promise.reject(error);
  }
);

tilecutApi.interceptors.response.use(
  response => {
    console.log(response);
    return response;
  },
  error => {
    console.log(error);
    return Promise.reject(error);
  }
);

export default tilecutApi;
