import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const styles = theme => ({
  typography: {
    useNextVariants: true
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 180,
    maxWidth: 200
  }
});

function TileSelect(props) {
  function handleChange(event) {
    props.onSelectionChange(event);
  }

  const name = props.name;
  const label = props.label;
  const selectList = props.selectList;

  const options = selectList.map(item => (
    <MenuItem key={item.id.toString()} value={item.id}>
      {item.name}
    </MenuItem>
  ));
  const option = props.option;
  const { classes } = props;

  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor={`${name}-open-select`}>{label}</InputLabel>
      <Select
        value={option}
        onChange={handleChange}
        inputProps={{
          name: name,
          id: `${name}-open-select`
        }}
      >
        {options}
      </Select>
    </FormControl>
  );
}

TileSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  onSelectionChange: PropTypes.func
};
export default withStyles(styles)(TileSelect);
