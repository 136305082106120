import React, { Component } from "react";
import { Grid, withStyles, CircularProgress, List } from "@material-ui/core";
import Tileset from "../components/Tiles/Tileset";
import * as apiService from "../api/TileCutVizAPI";
import SelectionsDrawer from "../components/Drawers/SelectionsDrawer";
import SettingsDrawer from "../components/Drawers/SettingsDrawer";

const styles = theme => ({
  marginLeft: 0,
  missingTile: {
    textAlign: "center",
    fontSize: "20px",
    color: "#a8a8a8"
  }
});

class TileView extends Component {
  state = {
    brandId: "",
    designId: "",
    colorwayItems: [],
    contentPackages: [],
    tilesets: [],
    colorwayOption: "",
    contentPackageOption: "",
    tilesetOption: "",
    loading: true,
    error: false
  };

  componentDidMount() {
    const designId = this.props.match.params.designId;

    apiService.getBrand(designId).then(response => {
      this.setState(
        { brandId: response.data, designId: designId },
        this.refreshColorways
      );
    });
  }

  handleSelectionChange = event => {
    const id = event.target.value;
    const name = event.target.name;
    this.setState({ loading: true });

    if (name == "colorway") {
      this.selectColorway(id);
    }

    if (name == "contentPackage") {
      this.selectContentPackage(id);
    }

    if (name == "tileSet") {
      this.selectTileset(id);
    }
  };

  refreshColorways = () => {
    return apiService.getColorways(this.state.designId).then(response => {
      let colorways = response.data.colorways.map(colorway => ({
        id: colorway.webColorwayId,
        name: `${colorway.colorwayCode} (${
          colorway.colorwayName == null ? "-" : colorway.colorwayName
        })`,
        isDefault: false
      }));

      colorways.push({
        id: -1,
        name: "Default"
      });

      this.setState({ colorwayItems: colorways }, this.selectColorway);
    });
  };

  selectColorway = colorwayId => {
    if (typeof colorwayId === "undefined") {
      colorwayId = this.state.colorwayItems[0].id || "";
    }

    this.setState({ colorwayOption: colorwayId }, this.refreshContentPackages);
  };

  refreshContentPackages = () => {
    return apiService
      .getContentPackages(this.state.brandId, this.state.designId)
      .then(response => {
        const contentPackages = response.data.map(cp => ({
          id: cp.contentPackageId,
          name: cp.contentPackageName,
          isDesignContentPackage: cp.designContentPackage !== null,
          isStandard: cp.isStandard,
          isBrandContentPackage: cp.brandContentPackage !== null,
          designContentPackageId:
            cp.designContentPackage !== null
              ? cp.designContentPackage.designContentPackageId
              : "",
          designId: this.state.designId
        }));

        contentPackages.sort((a, b) => {
          const compareDesign =
            b.isDesignContentPackage - a.isDesignContentPackage;
          const compareBrand =
            b.isBrandContentPackage - a.isBrandContentPackage;
          return compareDesign || compareBrand;
        });

        this.setState(
          { contentPackages: contentPackages },
          this.selectContentPackage
        );
      });
  };

  selectContentPackage = contentPackageId => {
    if (!contentPackageId) {
      let cp = this.state.contentPackages.find(cp => cp.isDesignContentPackage);

      if (!cp) {
        cp = this.state.contentPackages.find(cp => cp.isBrandContentPackage);
      }

      contentPackageId = cp ? cp.id : this.state.contentPackages[0].id;
    }

    this.setState(
      { contentPackageOption: contentPackageId },
      this.refreshTilesets
    );
  };

  refreshTilesets = () => {
    return apiService
      .getTilesets(this.state.contentPackageOption)
      .then(response => {
        const tilesets = response.data.map(tileset => ({
          id: tileset.contentEntryTilesetId,
          name: `${tileset.width}x${tileset.height}`
        }));
        this.setState({ tilesets: tilesets }, this.selectTileset);
      })
      .catch(error => {
        if (error.response.status === 404) this.setState({ error: true });
      });
  };

  selectTileset = tilesetId => {
    if (typeof tilesetId === "undefined") {
      tilesetId = this.state.tilesets[0].id || "";
    }

    this.setState({ tilesetOption: tilesetId, loading: false });
  };

  handleBackButtonClick = () => {
    this.props.history.push("/");
  };

  showTileset = () => {
    const { classes } = this.props;

    if (this.state.error) {
      return (
        <p className={classes.missingTile}>
          <b>No tile measurements available for this design</b>
        </p>
      );
    }
    if (this.state.loading) {
      return <CircularProgress size={150} />;
    } else if (this.state.colorwayOption && this.state.tilesetOption) {
      return (
        <Tileset
          colorwayId={this.state.colorwayOption}
          tilesetId={this.state.tilesetOption}
        />
      );
    }
  };

  toggleSelections = event => {
    this.props.onToggleSelections(event);
  };

  toggleSettings = event => {
    this.props.onToggleSettings(event);
  };

  render() {
    const { classes } = this.props;

    const showTileset = this.showTileset();
    const colorwayOption = this.state.colorwayOption;
    const contentPackageOption = this.state.contentPackageOption;
    const tilesetOption = this.state.tilesetOption;

    const colorwayItems = this.state.colorwayItems;
    const contentPackages = this.state.contentPackages;
    const tilesets = this.state.tilesets;
    const openSelections = this.props.openSelections;
    const openSettings = this.props.openSettings;

    return (
      <React.Fragment>
        <SelectionsDrawer
          openSelections={openSelections}
          onToggleSelections={this.toggleSelections}
          handleSelectionChange={this.handleSelectionChange}
          handleBackButtonClick={this.handleBackButtonClick}
          colorwayOption={colorwayOption}
          contentPackageOption={contentPackageOption}
          tilesetOption={tilesetOption}
          colorwayItems={colorwayItems}
          contentPackages={contentPackages}
          tilesets={tilesets}
        />
        <SettingsDrawer
          openSettings={openSettings}
          onToggleSettings={this.toggleSettings}
          handleSelectionChange={this.handleSelectionChange}
          contentPackageOption={contentPackageOption}
          contentPackages={contentPackages}
          refreshContentPackages={this.refreshContentPackages}
        />
        <Grid
          container
          item
          xs={12}
          spacing={24}
          direction="column"
          alignItems="center"
          justify="center"
        >
          <Grid item xs={12}>
            {showTileset}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(TileView);
