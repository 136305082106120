import React, { Component } from "react";
import { getTileRepeat, getTileImage } from "../../api/TileCutVizAPI";
import CircularProgress from "@material-ui/core/CircularProgress";

const tileviewWindow = {
  padding: "5px"
};
const defaultRepeatWidth = 400;
const defaultRepeatHeight = 400;
class Tileset extends Component {
  constructor(props) {
    super(props);

    this.canvasElement = React.createRef();
  }
  state = {
    tileset: null
  };
  componentDidMount() {
    this.handleTileset(this.props.colorwayId, this.props.tilesetId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tilesetId !== this.props.tilesetId) {
      if (this.state.tileset !== null) {
        this.setState({ tileset: null });
      }

      this.handleTileset(this.props.colorwayId, this.props.tilesetId);
    }
  }

  handleTileset = (colorwayId, tilesetId) => {
    getTileRepeat(colorwayId, tilesetId).then(response => {
      const tileset = response.data;

      let image;
      if (tileset.repeat) {
        image = {
          width: tileset.repeat.widthPixels,
          height: tileset.repeat.heightPixels,
          fullRepeatTileBase64: `data:image/jpeg;base64,${tileset.repeat.image}`
        };
      }

      const selectedTile = {
        tilesetId: tilesetId,
        repeatImage: image,
        width: tileset.offsetDimensions.totalWidthPixels,
        height: tileset.offsetDimensions.totalHeightPixels,
        offsetCoords: tileset.offsetDimensions.allOffsets.map(c => ({
          offsetId: c.id,
          x1: c.x1,
          y1: c.y1,
          x2: c.x2,
          y2: c.y2
        }))
      };

      return this.setState(
        {
          tileset: selectedTile
        },
        this.setCanvas
      );
    });
  };

  setCanvas = () => {
    const { repeatImage } = this.state.tileset;

    const dynamicCanvas = document.createElement("canvas");

    const rctx = dynamicCanvas.getContext("2d");

    if (repeatImage) {
      dynamicCanvas.width = repeatImage.width;
      dynamicCanvas.height = repeatImage.height;
      this.setupImage(repeatImage, rctx, dynamicCanvas);
    } else {
      dynamicCanvas.width = defaultRepeatWidth;
      dynamicCanvas.height = defaultRepeatHeight;
      rctx.fillStyle = "#777777";
      rctx.fillRect(0, 0, defaultRepeatWidth, defaultRepeatHeight);
      this.addRepeatText(rctx);
      this.addRepeatBorder(rctx);
      this.drawRepeat(dynamicCanvas);
    }
  };

  setupImage(repeatImage, rctx, dynamicCanvas) {
    let imageElement = new Image(repeatImage.width, repeatImage.height);
    imageElement.src = this.state.tileset.repeatImage.fullRepeatTileBase64;

    imageElement.onload = () => {
      imageElement.alt = "fullrepeat";
      rctx.drawImage(
        imageElement,
        0,
        0,
        imageElement.width,
        imageElement.height
      );

      this.addRepeatBorder(rctx);
      this.drawRepeat(dynamicCanvas);
    };
  }

  addRepeatText(rctx) {
    rctx.font = "20px Arial bold";
    rctx.textAlign = "center";
    rctx.textBaseline = "middle";
    rctx.fillStyle = "#a8a8a8";

    var rectX = 0;
    var rectY = 0;
    rctx.fillText(
      "No Image",
      rectX + defaultRepeatWidth / 2,
      rectY + defaultRepeatHeight / 2
    );
  }

  addRepeatBorder(rctx) {
    rctx.lineWidth = 7;
    rctx.setLineDash([5, 10]);
    rctx.strokeStyle = "white";

    let height = rctx.canvas.height;
    let width = rctx.canvas.width;
    if (this.state.tileset.repeatImage) {
      width = this.state.tileset.repeatImage.width;
      height = this.state.tileset.repeatImage.height;
    }

    rctx.strokeRect(0, 0, width, height);
  }

  drawRepeat(dynamicCanvas) {
    const { offsetCoords, width, height } = this.state.tileset;
    const canvas = this.canvasElement.current;
    const containerCtx = canvas.getContext("2d");

    const pattern = containerCtx.createPattern(dynamicCanvas, "repeat");

    containerCtx.fillStyle = pattern;
    containerCtx.fillRect(0, 0, width, height);
    containerCtx.lineWidth = 3;
    containerCtx.strokeStyle = "red";
    offsetCoords.forEach(coord => {
      containerCtx.strokeRect(
        coord.x1,
        coord.y1,
        coord.x2 - coord.x1,
        coord.y2 - coord.y1
      );
    });
  }

  displayImage() {
    return (
      <div>
        <canvas
          ref={this.canvasElement}
          width={this.state.tileset.width}
          height={this.state.tileset.height}
        />
      </div>
    );
  }

  render() {
    return (
      <div style={tileviewWindow}>
        {this.state.tileset === null ? (
          <CircularProgress size={150} />
        ) : (
          this.displayImage()
        )}
      </div>
    );
  }
}

export default Tileset;
