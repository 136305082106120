import React, { Component } from "react";
import {
  Grid,
  withStyles,
  List,
  ListItem,
  Card,
  CircularProgress,
  CardContent,
  Typography
} from "@material-ui/core";
import * as apiService from "../api/TileCutVizAPI";
import { Redirect } from "react-router-dom";
import axios from "axios";
import {
  InfiniteLoader,
  AutoSizer,
  List as VirtualList
} from "react-virtualized";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  brandnav: {
    backgroundColor: theme.palette.background.paper,
    maxHeight: window.innerHeight - 90,
    position: "relative",
    overflow: "auto",
    minWidth: 175
  },
  designContent: {
    backgroundColor: theme.palette.background.paper
  },
  designCard: {
    cursor: "pointer",
    display: "inline-flex",
    marginLeft: 25,
    marginTop: 5
  },
  paper: {
    padding: theme.spacing.unit,
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  progress: {
    marginLeft: "40%",
    marginTop: "15%"
  },
  toolbar: theme.mixins.toolbar
});

class DesignsView extends Component {
  state = {
    loading: true,
    brandItems: [],
    designItems: [],
    brandOption: "",
    designOption: "",
    designPagination: ""
  };

  componentDidMount() {
    this.getBrands();
  }

  handlebrandListItemClick = (event, id) => {
    this.setState({ brandOption: id, loading: true }, this.getDesigns);
  };

  handleDesignCardClick = (event, id) => {
    this.setState({ designOption: id });
  };

  getDesigns = () => {
    return apiService.getDesigns(this.state.brandOption).then(response => {
      const xPage = response.headers["x-pagination"];
      const designPagination = JSON.parse(xPage);

      const designs = response.data.map(design => ({
        id: design.designId,
        name: design.designName,
        code: design.designCode
      }));

      this.setState({
        designPagination: designPagination,
        designItems: this.to2dArray(designs),
        loading: false
      });
    });
  };

  getBrands = brandId => {
    return apiService.getBrands().then(response => {
      const brands = response.data.map(brand => ({
        id: brand.brandId,
        name: brand.name
      }));
      this.setState(
        {
          brandItems: brands
        },
        function() {
          this.selectBrand(brandId);
        }
      );
    });
  };

  selectBrand = brandId => {
    if (!brandId) {
      brandId = this.state.brandItems[0].id;
    }

    this.setState({ brandOption: brandId }, this.getDesigns);
  };

  displayBrandList() {
    const brandItems = this.state.brandItems.map(brandItem => {
      return (
        <ListItem
          key={brandItem.id.toString()}
          button
          selected={this.state.brandOption === brandItem.id}
          onClick={event => this.handlebrandListItemClick(event, brandItem.id)}
        >
          {brandItem.name}
        </ListItem>
      );
    });

    return brandItems;
  }

  rowRenderer = ({ key, index, isScrolling, isVisible, style }) => {
    const { classes } = this.props;

    const designArr = this.state.designItems;
    if (designArr[index]) {
      const content = designArr[index].map((design, colIndex) => {
        return (
          <Card
            key={`${colIndex}${key}`}
            className={classes.designCard}
            onClick={event => this.handleDesignCardClick(event, design.id)}
          >
            <CardContent style={{ width: 120, height: 100 }}>
              <Typography noWrap={true} component="h2">
                {design.name}
              </Typography>
              <Typography noWrap={true} color="textSecondary">
                {design.code}
              </Typography>
            </CardContent>
          </Card>
        );
      });

      return (
        <div key={key} style={style}>
          {content}
        </div>
      );
    }
  };

  isRowLoaded = ({ index }) => {
    return !!this.state.designItems[index];
  };

  loadMoreRows = ({ startIndex, stopIndex }) => {
    const { designPagination, designItems } = this.state;

    if (designItems.length !== designPagination.totalCount) {
      return axios.get(designPagination.nextPageLink).then(response => {
        const xPage = response.headers["x-pagination"];
        const designPagination = JSON.parse(xPage);

        const designs = response.data.map(design => ({
          id: design.designId,
          name: design.designName,
          code: design.designCode
        }));
        let newDesignItems = [...designItems];
        let result = newDesignItems.concat(this.to2dArray(designs));

        this.setState({
          designItems: result,
          designPagination: designPagination
        });
      });
    }
  };

  displayDesignInfiniteLoader() {
    return (
      <InfiniteLoader
        minimumBatchSize={15}
        isRowLoaded={this.isRowLoaded}
        loadMoreRows={this.loadMoreRows}
        rowCount={Math.ceil(this.state.designPagination.totalCount / 8)}
      >
        {({ onRowsRendered, registerChild }) => (
          <AutoSizer disableHeight>
            {({ width }) => (
              <VirtualList
                style={{ outline: "none", paddingTop: 5 }}
                ref={registerChild}
                onRowsRendered={onRowsRendered}
                rowRenderer={this.rowRenderer}
                height={window.innerHeight - 150}
                width={width}
                rowCount={Math.ceil(this.state.designPagination.totalCount / 8)}
                rowHeight={160}
              />
            )}
          </AutoSizer>
        )}
      </InfiniteLoader>
    );
  }

  to2dArray = array1d => {
    let array2d = [];
    while (array1d.length) {
      array2d.push(array1d.splice(0, 8));
    }
    return array2d;
  };

  render() {
    if (this.state.designOption) {
      return <Redirect to={`/design/${this.state.designOption}`} />;
    }

    const { classes } = this.props;

    const brands = this.displayBrandList();

    const designs = this.state.loading ? (
      <CircularProgress className={classes.progress} size={150} thickness={1} />
    ) : (
      this.displayDesignInfiniteLoader()
    );

    return (
      <React.Fragment>
        <Grid
          container
          item
          xs={2}
          direction="row"
          className={classes.brandnav}
        >
          <Grid item>
            <label>
              <b>Brand Selection</b>
            </label>
          </Grid>
          <Grid item>
            <List>{brands}</List>
          </Grid>
        </Grid>
        <Grid
          className={classes.designContent}
          container
          item
          xs={5}
          sm={10}
          spacing={8}
          direction="row"
        >
          <Grid container item>
            {designs}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(DesignsView);
