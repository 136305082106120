import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography, CardContent } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import TileSelect from "../components/Selectors/TileSelector";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import { updateDesignContentPackage } from "../api/TileCutVizAPI";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Fade from "@material-ui/core/Fade";

const styles = theme => ({
  root: {
    width: 350,
    padding: 8 * 3
  },
  button: {
    margin: theme.spacing.unit
  },
  saveIcon: {
    fontSize: 20,
    marginRight: theme.spacing.unit
  }
});

class DesignContentPackage extends Component {
  state = {
    contentPackageId: "",
    contentPackagesWithDefault: [],
    showSuccess: false
  };

  componentDidMount() {
    const contentPackages = [...this.props.contentPackages];

    contentPackages.unshift({
      id: -1,
      name: "None Assigned"
    });

    let cp = this.props.contentPackages.find(cp => cp.isDesignContentPackage);

    const contentPackageId = cp ? cp.id : -1;

    this.setState({
      contentPackageId: contentPackageId,
      contentPackagesWithDefault: contentPackages
    });
  }
  handleDesignContentPackageChange = event => {
    const id = event.target.value;

    this.setState({ contentPackageId: id });
  };
  AddOrUpdateDesignContentPackage = () => {
    const contentPackageId = this.state.contentPackageId;
    if (contentPackageId == -1) {
      // do nothing for now
      // todo: remove content package from design content package?
      return;
    }

    const contentPackage = this.props.contentPackages.find(
      cp => cp.id === contentPackageId && cp.id > 0
    );

    if (!contentPackage.isDesignContentPackage) {
      updateDesignContentPackage(
        contentPackage.designId,
        contentPackage.id
      ).then(response => {
        if (response.status === 200) {
          this.setState({ showSuccess: true });
          setTimeout(() => {
            this.setState({
              showSuccess: false
            });
          }, 3000);
        }
      });
      this.props.refreshContentPackages();
    }
  };
  displayMessage() {
    return (
      <Fade in={this.state.showSuccess} timeout={1100}>
        <CheckCircleIcon style={{ color: "green" }} />
      </Fade>
    );
  }

  render() {
    const { classes } = this.props;
    const contentPackageList = this.state.contentPackagesWithDefault;

    const contentPackageId = this.state.contentPackageId;
    return (
      <div className={classes.root}>
        <Card>
          <CardContent>
            <Typography gutterBottom variant="subheading">
              Design Content Package {this.displayMessage()}
            </Typography>
            <Typography component="p">
              Select new design content package. This action may delete the
              current selection.
            </Typography>
            <TileSelect
              label="Design Content Package"
              name="designContentPackage"
              selectList={contentPackageList}
              option={contentPackageId}
              onSelectionChange={this.handleDesignContentPackageChange}
            />

            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}
              onClick={this.AddOrUpdateDesignContentPackage}
            >
              <SaveIcon className={classes.saveIcon} />
              Save
            </Button>
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles)(DesignContentPackage);
