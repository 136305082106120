import React from "react";
import ReactDOM from "react-dom";
import MainView from "./MainView";
import CssBaseline from "@material-ui/core/CssBaseline";
import { BrowserRouter as Router } from "react-router-dom";
import { hot } from "react-hot-loader";
import registerServiceWorker from "../registerServiceWorker";

function AppContainer() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Router>
        <MainView />
      </Router>
    </React.Fragment>
  );
}

let App;
if (module.hot) {
  App = hot(module)(AppContainer);
} else {
  App = AppContainer;
}

const wrapper = document.getElementById("tilecut-app-container");
wrapper ? ReactDOM.render(<App />, wrapper) : false;

registerServiceWorker();

export default AppContainer;
