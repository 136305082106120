import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import SettingsIcon from "@material-ui/icons/Settings";
import MenuIcon from "@material-ui/icons/Menu";

const styles = theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 101
  },
  profileMenu: {
    zIndex: 1501
  },

  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  toolbar: theme.mixins.toolbar
});

class NavBar extends Component {
  state = {
    value: 0,
    auth: true,
    anchorEl: null
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };
  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  handleLogOut = () => {
    location.pathname = "/account/logout";
  };
  toggleSettings = event => {
    this.props.onToggleSettings(event);
  };

  toggleSelections = event => {
    this.props.onToggleSelections(event);
  };

  showSettingsIcon = () => {
    if (!this.props.hideSettingsIcon) {
      return (
        <IconButton color="inherit" onClick={this.toggleSettings}>
          <SettingsIcon />
        </IconButton>
      );
    }
  };

  showSelectionIcon = () => {
    const { classes } = this.props;
    if (!this.props.hideSelectionIcon) {
      return (
        <IconButton
          onClick={this.toggleSelections}
          className={classes.menuButton}
          color="inherit"
          aria-label="Menu"
        >
          <MenuIcon />
        </IconButton>
      );
    }
  };

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <React.Fragment>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            {this.showSelectionIcon()}
            <Typography variant="h6" color="inherit" className={classes.grow}>
              Tile Cut Visualizer
            </Typography>
            {this.showSettingsIcon()}
            <IconButton
              aria-owns={open ? "menu-appbar" : undefined}
              aria-haspopup="true"
              onClick={this.handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              className={classes.profileMenu}
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              open={open}
              onClose={this.handleClose}
            >
              <MenuItem onClick={this.handleLogOut}>Log Out</MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <div className={classes.toolbar} />
      </React.Fragment>
    );
  }
}

NavBar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NavBar);
