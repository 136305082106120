import React from "react";
import TileSelect from "./TileSelector";
import ListItem from "@material-ui/core/ListItem";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
  item: {
    padding: "0px"
  }
});

function Selectors(props) {
  function handleColorwaySelectionChange(event) {
    props.onColorwaySelectionChange(event);
  }
  function handleContentPackageSelectionChange(event) {
    props.onContentPackageSelectionChange(event);
  }
  function handleTilesetSelectionChange(event) {
    props.onTilesetSelectionChange(event);
  }

  const { classes } = props;
  const colorwayOption = props.colorwayOption;
  const contentPackageOption = props.contentPackageOption;
  const tilesetOption = props.tilesetOption;

  const colorwayItems = props.colorwayItems;
  const contentPackages = props.contentPackages;
  const tilesets = props.tilesets;

  return (
    <React.Fragment>
      <ListItem key="1" className={classes.item}>
        <TileSelect
          name="colorway"
          label="Colorway"
          selectList={colorwayItems}
          option={colorwayOption}
          onSelectionChange={handleColorwaySelectionChange}
        />
      </ListItem>

      <ListItem key="2" className={classes.item}>
        <TileSelect
          name="contentPackage"
          label="Content Package"
          selectList={contentPackages}
          option={contentPackageOption}
          onSelectionChange={handleContentPackageSelectionChange}
        />
      </ListItem>
      <ListItem key="3" className={classes.item}>
        <TileSelect
          name="tileSet"
          label="Tile Set"
          selectList={tilesets}
          option={tilesetOption}
          onSelectionChange={handleTilesetSelectionChange}
        />
      </ListItem>
    </React.Fragment>
  );
}

export default withStyles(styles)(Selectors);
