import React from "react";
import { List } from "@material-ui/core";
import TileSelectors from "../components/Selectors/TileSelectors";

function SelectionsView(props) {
  function onSelectionChange(event) {
    props.handleSelectionChange(event);
  }

  const colorwayOption = props.colorwayOption;
  const contentPackageOption = props.contentPackageOption;
  const tilesetOption = props.tilesetOption;

  const colorwayItems = props.colorwayItems;
  const contentPackages = props.contentPackages;
  const tilesets = props.tilesets;

  return (
    <List>
      <TileSelectors
        onColorwaySelectionChange={onSelectionChange}
        onContentPackageSelectionChange={onSelectionChange}
        onTilesetSelectionChange={onSelectionChange}
        colorwayOption={colorwayOption}
        contentPackageOption={contentPackageOption}
        tilesetOption={tilesetOption}
        colorwayItems={colorwayItems}
        contentPackages={contentPackages}
        tilesets={tilesets}
      />
    </List>
  );
}

export default SelectionsView;
