import React from "react";
import Drawer from "@material-ui/core/Drawer";
import DesignContentPackage from "../../containers/DesignContentPackage";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  toolbar: theme.mixins.toolbar
});

function SettingsDrawer(props) {
  function toggleSettings(event) {
    props.onToggleSettings(event);
  }

  const { classes } = props;

  const contentPackages = props.contentPackages;
  const openSettings = props.openSettings;
  const refreshContentPackages = props.refreshContentPackages;

  return (
    <Drawer anchor="right" open={openSettings} onClose={toggleSettings}>
      <div className={classes.toolbar} />

      <DesignContentPackage
        contentPackages={contentPackages}
        refreshContentPackages={refreshContentPackages}
      />
    </Drawer>
  );
}

export default withStyles(styles)(SettingsDrawer);
